* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
}

html {
    font-size: 62.5%;
}

body {
    background-color: rgb(69 57 248 / 5%);
}

a {
    text-decoration: none;

}

li {
    list-style: none;
}

.logo h2 {
    margin-top: 1rem;
    font-size: 4.5rem;
    font-weight: 400;




}

.main-nav {
    width: 100%;
    height: 6.5rem;

    box-shadow: 10px 5px 5px grey;
    background-color: black;
    color: white;
    position: sticky;
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 10rem;

}



::placeholder {

    font-size: 1.6rem;
}




.logo {

    grid-column: 2/3;
    justify-content: center;

}



.logo h2 {
    position: relative;
    top: .8rem;
    font-size: 3rem;
    font-weight: 500;

}

.container {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 4rem auto;
    width: 70%;
    height: 85rem;
    overflow-y: scroll;
    place-items: center;
    gap: 2rem;
    background-color: white;
}

.cards {
    margin: 1rem 0;
    width: 30rem;
    height: 30rem;
    background-color: whitesmoke;


}

.cards img {
    width: 29.8rem;
    height: 24rem;
}

.title_class {

    margin: 1rem 0;
    text-align: center;
}

.title_class h3 {
    font-size: 1.4rem;
    color: black;
}